<template>
  <main class="LayoutPublic layout layout-public" :class="{ 'layout-login': isLoginPage }">
    <div class="layout-side" v-if="isLoginPage">
      <div>
        <h1>Welcome to CFT Platform</h1>

        <p>This platform offers up-to-date, online views of CFT customer accounts (Binance).</p>

        <p>The CFT platform allows you to visualise your portfolio’s performances based on the trades made on Binance, and review such information as trading history, live trading, and account status.</p>

        <p>CFT’s team remains at your disposal for any enquiries you may have about your account.</p>

        <div class="links">
          <a class="link" href="https://accounts.binance.com/en/register?ref=15396863" target="_blank" title="Go to Binance">
            <img src="@/assets/img/exchances/binance.svg" alt="Binance logo">
            <p>Binance</p>
          </a>

          <!-- <a class="link" href="https://ftx.com/#a=CFTgroup" target="_blank" title="Go to FTX">
            <img src="@/assets/img/exchances/ftx.svg" alt="FTX logo">
            <p>Ftx</p>
          </a> -->
        </div>
      </div>
    </div>
    <div class="layout-side" v-else />

    <div class="layout-main">
      <div class="layout-view">
        <router-view />
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    computed: {
      isLoginPage() {
        return this.$route.name === 'auth-login'
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all'

  .LayoutPublic
    display: flex
    overflow: hidden
    min-height: 100vh

  .layout-side
    padding: $spacer-5
    flex: 1
    background: $primary url('~@/assets/img/bg.jpg') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
    display: none

    @include media-breakpoint-up(lg)
      display: flex

  .layout-main
    padding: $spacer-5
    flex: 2
    overflow: auto
    display: flex
    justify-content: center
    align-items: center

    .layout-login &
      flex: 1

  .layout-view
    width: 100%

    .layout-narrow
      max-width: 500px

    .layout-large
      max-width: 750px

  .layout-side
    flex-direction: column
    align-items: center
    justify-content: center

    div
      color: rgba(white, .75)
      max-width: 400px

    h1
      color: white
      text-transform: uppercase
      font-weight: normal
      font-size: 1.65em
      text-align: center

      &:after
        content: '—'
        display: block
        margin: .5em 0 .75em
        color: rgba(white, .5)

    .links
      display: flex
      gap: 1em
      text-transform: uppercase
      margin-top: 2.5em
      font-weight: bold

      .link
        background: white
        flex: 1 1 auto
        padding: .25em
        text-align: center
        border-radius: .5em

        img
          height: 100px
</style>
