<template>
  <div class="AppSidebar">
    <div class="AppSidebar__header">
      <button class="btn btn-text btn-dark AppSidebar__menu-toggle" @click="onToggleSidebarMinified">
        <i v-if="isSidebarMinified" class="bi bi-list" />
        <i v-if="!isSidebarMinified" class="bi bi-arrow-left-short" />
      </button>
    </div>

    <ul class="AppSidebar__menu">
      <li>
        <router-link class="AppSidebar__item" :to="{ name: 'dashboard' }" title="Dashboard">
          <div class="AppSidebar__icon">
            <i class="bi bi-speedometer2" />
          </div>
          <div class="AppSidebar__text">
            Dashboard
          </div>
        </router-link>
      </li>

      <li v-if="!isAuthAdmin || !isAuthAgent || isAuthImpersonate">
        <router-link class="AppSidebar__item" :to="{ name: 'allocations' }" title="Allocations">
          <div class="AppSidebar__icon">
            <i class="bi bi-wallet" />
          </div>
          <div class="AppSidebar__text">
            Allocations
          </div>
        </router-link>
      </li>

      <li v-if="(!isAuthAdmin && !isAuthAgent) || isAuthImpersonate">
        <router-link class="AppSidebar__item" :to="{ name: 'transfers' }" title="Transfers">
          <div class="AppSidebar__icon">
            <i class="bi bi-arrow-left-right" />
          </div>
          <div class="AppSidebar__text">
            Transfers
          </div>
        </router-link>
      </li>

      <li v-if="(!isAuthAdmin && !isAuthAgent) || isAuthImpersonate" class="AppSidebar__admin">
        <router-link class="AppSidebar__item" :to="{ name: 'aums' }" title="AUM">
          <div class="AppSidebar__icon">
            <i class="bi bi-bar-chart" />
          </div>
          <div class="AppSidebar__text">
            AUM
          </div>
        </router-link>
      </li>

      <li v-if="(!isAuthAdmin && !isAuthAgent) || isAuthImpersonate">
        <router-link class="AppSidebar__item" :to="{ name: 'invoices' }" title="Invoices">
          <div class="AppSidebar__icon">
            <i class="bi bi-receipt-cutoff" />
          </div>
          <div class="AppSidebar__text">
            Invoices
          </div>
        </router-link>
      </li>

      <li>
        <router-link class="AppSidebar__item" :to="{ name: 'account' }" title="My account">
          <div class="AppSidebar__icon">
            <i class="bi bi-person" />
          </div>
          <div class="AppSidebar__text">
            My account
          </div>
        </router-link>
      </li>

      <li v-if="isAuthAdmin || isAuthAgent" class="AppSidebar__admin">
        <router-link class="AppSidebar__item" :to="{ name: 'accounting' }" title="Accounting">
          <div class="AppSidebar__icon">
            <i class="bi bi-currency-dollar" />
          </div>
          <div class="AppSidebar__text">
            Accounting
          </div>
        </router-link>
      </li>

      <li v-if="isAuthAdmin || isAuthAgent" class="AppSidebar__admin">
        <router-link class="AppSidebar__item" :to="{ name: 'clients' }" title="Clients">
          <div class="AppSidebar__icon">
            <i class="bi bi-people" />
          </div>
          <div class="AppSidebar__text">
            Clients
          </div>
        </router-link>
      </li>

      <li v-if="isAuthAdmin" class="AppSidebar__admin">
        <router-link class="AppSidebar__item" :to="{ name: 'agents' }" title="Agents">
          <div class="AppSidebar__icon">
            <i class="bi bi-bookmarks" />
          </div>
          <div class="AppSidebar__text">
            Agents
          </div>
        </router-link>
      </li>

      <li v-if="isAuthAdmin" class="AppSidebar__admin">
        <router-link class="AppSidebar__item" :to="{ name: 'settings' }" title="Settings">
          <div class="AppSidebar__icon">
            <i class="bi bi-gear" />
          </div>
          <div class="AppSidebar__text">
            Settings
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapState('app', [
        'isSidebarMinified'
      ]),

       ...mapState('auth', [
        'authenticated',
        'impersonated'
      ]),

      ...mapGetters('auth', [
        'isAuthAdmin',
        'isAuthAgent',
        'isAuthImpersonate'
      ])
    },

    methods: {
      ...mapActions('app', [
        'toggleSidebarMinified'
      ]),

      onToggleSidebarMinified() {
        this.toggleSidebarMinified()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AppSidebar
    width: $sidebar-width
    height: 100vh
    border-right: 1px solid lighten($secondary, 10)
    transition: width .25s
    color: $white
    display: flex
    flex-direction: column
    background: $primary url('~@/assets/img/bg.jpg') center center no-repeat
    background-blend-mode: multiply
    background-size: cover

    a
      text-decoration: none
      color: currentColor

  .AppSidebar__menu-toggle
    display: none
    color: currentColor
    background: none

    @include media-breakpoint-up(sm)
      display: block
      margin-left: .75em

  .AppSidebar__header
    height: $header-height
    display: flex
    align-items: center

    i
      font-size: 1.62rem

  .AppSidebar__menu
    padding: 0
    flex: 1

  .AppSidebar__item
    display: flex
    align-items: center
    border-left: 3px solid transparent
    color: $white
    opacity: .75
    transition: .5s all

    &:hover
      opacity: 1

  .AppSidebar__icon
    padding: .5em 1em
    font-size: 1.5rem
    margin-left: 1px

  .AppSidebar__text
    font-size: 12px
    transition: opacity .25s
    white-space: nowrap

  .AppSidebar .active
    &
      opacity: 1
      border-color: $tertiary
      background: rgba($secondary, .4)

    .AppSidebar__icon
      color: $white

    .AppSidebar__text
      color: $white
      font-weight: bold

  .AppSidebar__admin
    .AppSidebar__icon
      color: $tertiary

    & .active
      .AppSidebar__icon
        color: $tertiary

  @include media-breakpoint-up(sm)
    .sidebar-is-minified
      .AppSidebar
        width: $sidebar-width-minified

      .AppSidebar__text
        opacity: 0

</style>

