import Theme from '@/utils/theme'

const myTheme = new Theme({
  primary: 'bs-primary',
  secondary: 'bs-secondary',
  tertiary: [ '#c8a05c', (value) => !value ? '#FF0000' : value ],
  success: 'bs-success',
  danger: 'bs-danger',
  warning: 'bs-warning',
  info: 'bs-info'
})

export default myTheme

