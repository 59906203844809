const API_URL = 'https://api.cryptosfundtrading.com/api'

export const settings = {
  api: {
    url: API_URL
  },
  passwordValidRE: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
  underConstuction: true
}

export const validationTexts = {
  required: 'Required field',
  email: 'Invalid email address',
  url: 'Invalid URL',
  minValue: 'Invalid number (minimum value)',
  maxValue: 'Invalid number (maximum value)',
  sameAsPassword: 'Password must be identical',
  passwordStrength: 'Password invalid format',
  decimal: 'Invalid number (decimal)',
  integer: 'Invalid number (integer)',
  numeric: 'Invalid number (numeric)'
}

export const exchanges = {
  binance: {
    logo: require('@/assets/img/exchances/binance.svg')
  },
  fbinance: {
    logo: require('@/assets/img/exchances/binance.svg')
  },
  fftx: {
    logo: require('@/assets/img/exchances/ftx.svg')
  },
  ftx: {
    logo: require('@/assets/img/exchances/ftx.svg')
  },
  bybit: {
    logo: require('@/assets/img/exchances/bybit.svg')
  },
  fbybit: {
    logo: require('@/assets/img/exchances/bybit.svg')
  }
}
