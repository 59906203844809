<template>
  <div class="UserAvatar">
    {{ initials }}
  </div>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        userData: this.user
      }
    },

    computed: {
      initials() {
        if (!this.userData) {
          return ''
        }

        let initials = ''

        initials += this.userData.firstname.substr(0, 1)
        initials += this.userData.lastname.substr(0, 1)

        return initials
      }
    },

    watch: {
      user(value) {
        this.userData = value
      }
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.vars.all'

  .UserAvatar
    display: flex
    justify-content: center
    align-items: center
    background-color: $tertiary
    width: 3em
    height: 3em
    color: $white
    border-radius: 50%
    font-size: .75em
    text-transform: uppercase
</style>
