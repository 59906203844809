<template>
  <main class="DashboardView">
    <div class="container">
      <AppLoading v-if="loading" />

      <div v-if="!loading">
        <section class="DashboardView__chart mb-4">
          <button type="button" class="DashboardView__refresh btn btn-outline-primary btn-sm" @click="refresh()" title="Refresh data">
            <i class="bi bi-arrow-repeat" />
          </button>
          <ClientAccountChart :account="account" />
        </section>

        <div class=" mb-4" v-if="allocations">
          <draggable
            item-key="id"
            class="DashboardView__allocations"
            ghost-class="DashboardView__allocations__item--ghost"
            handle=".DashboardView__allocations__item__handle"
            :list="allocations"
            @end="onDragEnd"
          >
            <template #item="{ element }">
              <div class="DashboardView__allocations__item">
                <div class="DashboardView__allocations__item__handle">
                  <i class="bi bi-arrows-move" />
                </div>
                <AllocationCard :allocation="element" />
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading.vue'
  import AllocationCard from '@/components/AllocationCard.vue'
  import ClientAccountChart from '@/components/ClientAccountChart.vue'

  import allocationService from '@/services/allocations.service'

  import useToast from '@/plugins/toast/useToast'
  import draggable from 'vuedraggable'

  export default {
    components: {
      AppLoading,
      AllocationCard,
      ClientAccountChart,
      draggable
    },

    setup() {
      return {
        toast$: useToast()
      }
    },

    data() {
      return {
        loading: false,
        account: null,
        allocations: null,

        enabled: true,
        dragging: false
      }
    },

    async mounted() {
      this.load()
    },

    methods: {
      async load(force) {
        this.loading = true

        const response = await allocationService.fetchDashboard(force)

        this.account = response.account
        this.allocations = response.allocations

        this.loading = false
      },

      refresh() {
        this.load(true)
      },

      onDragEnd() {
        this.toast$.success('Reordering', 'The new ordering has been saved.')
        allocationService.savePositions(this.allocations)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .DashboardView__chart
    position: relative

  .DashboardView__refresh
    position: absolute
    right: 0
    top: 1em
    z-index: 1

  .DashboardView__allocations
    display: flex
    gap: $spacer
    flex-wrap: wrap
    justify-content: center
    align-content: center
    align-items: center

  .DashboardView__allocations__item
    position: relative
    flex-grow: 1
    flex-shrink: 1
    flex-basis: 100%
    width: 100%

    &--ghost
      opacity: .2

    @include media-breakpoint-up(md)
      flex-grow: 0
      flex-shrink: 1
      flex-basis: calc(50% - #{$spacer})
      width: 0

    @include media-breakpoint-up(lg)
      flex-grow: 0
      flex-shrink: 1
      flex-basis: calc(33.33% - #{$spacer})

    @include media-breakpoint-up(xl)
      flex-grow: 0
      flex-shrink: 1
      flex-basis: calc(20% - #{$spacer})

  .DashboardView__allocations__item__handle
    cursor: move
    position: absolute
    top: 1em
    right: 1em
    z-index: 1
    opacity: 0
    transition: .25s

    .DashboardView__allocations__item:hover &
      opacity: 1
</style>
