<template>
  <header class="AppHeader">
    <button class="btn AppHeader__menu-toggle" @click="onToggleSidebar">
      <i v-if="!isSidebarOpen" class="bi bi-list" />
      <i v-if="isSidebarOpen" class="bi bi-arrow-left-short" />
    </button>

    <div class="AppHeader__brand">
      <router-link class="AppSidebar__item" :to="{ name: 'dashboard' }">
        <IconLogo class="AppHeader__logo" />
      </router-link>
    </div>

    <div class="AppHeader__authenticated">
      <DropdownMenu align="end">
        <UserAvatar :user="authenticated" />

        <template #menu>
          <DropdownItem :to="{ name: 'account' }">
            My account
          </DropdownItem>
          <DropdownDivider />
          <DropdownItem @click="onLogout">
            Logout
          </DropdownItem>
        </template>
      </DropdownMenu>
    </div>

    <div class="AppHeader__impersonation" v-if="impersonated">
      {{ impersonated.firstname }}
      {{ impersonated.lastname }}

      <button class="btn btn-link" @click="onUnsetImpersonated()">
        &times;
      </button>
    </div>
  </header>
</template>

<script>
  import IconLogo from '@/components/IconLogo'
  import UserAvatar from '@/components/UserAvatar'
  import DropdownMenu from '@/components/base/DropdownMenu'
  import DropdownItem from '@/components/base/DropdownItem'
  import DropdownDivider from '@/components/base/DropdownDivider'

  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      IconLogo,
      UserAvatar,
      DropdownMenu,
      DropdownItem,
      DropdownDivider
    },
    data() {
      return {
        isAuthenticatedMenuOpen: false
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ]),
      ...mapState('app', [
        'isSidebarOpen'
      ])
    },

    methods: {
      ...mapActions('app', [
        'toggleSidebar'
      ]),

      ...mapActions('auth', [
        'logout',
        'unsetImpersonated'
      ]),

      onToggleSidebar() {
        this.toggleSidebar()
      },

      onLogout() {
        this.logout()
      },

      onToggleAuthenticatedMenu() {
        this.isAuthenticatedMenuOpen = !this.isAuthenticatedMenuOpen
      },

      onUnsetImpersonated() {
        this.unsetImpersonated()
        this.$router.push({ name: 'clients' })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AppHeader
    height: $header-height
    width: 100%
    display: flex
    align-items: center
    border-bottom: 1px solid $gray-400
    background: $white

  .AppHeader__menu-toggle
    display: block
    margin-left: $spacer

    i
      font-size: 1.4rem

    @include media-breakpoint-up(sm)
      display: none

  .AppHeader__logo
    height: 35px
    margin: auto $spacer

    :deep(*)
      fill: $tertiary

  .AppHeader__authenticated
    margin-left: auto
    border-left: 1px solid $gray-400
    height: 100%
    padding: $spacer
    display: flex
    align-items: center

  .AppHeader__impersonation
    border-left: 1px solid $gray-400
    height: 100%
    padding: $spacer 0 $spacer $spacer-4
    display: flex
    align-items: center
    background: $primary
    color: white

    .btn
      color: white
</style>

