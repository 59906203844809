<template>
  <div class="ToastStack ToastStack-top">
    <transition-group :name="'ToastStack-' + transitionName">
      <ToastBase
        v-for="toast in tops"
        v-bind="toast.props"
        :key="toast.id"
        :toast="toast"
      />
    </transition-group>
  </div>
  <div class="ToastStack ToastStack-bottom">
    <transition-group :name="'ToastStack-' + transitionName">
      <ToastBase
        v-for="toast in bottoms"
        v-bind="toast.props"
        :key="toast.id"
        :toast="toast"
      />
    </transition-group>
  </div>
</template>

<script>
  import ToastBase from './ToastBase'
  import useToast from './useToast'

  export default {
    components: {
      ToastBase
    },

    setup() {
      return {
        toast$: useToast()
      }
    },

    computed: {
      transitionName() {
        return this.toast$.settings.animation ? 'appear' : 'none'
      },

      tops() {
        return this.toast$.toasts.filter((item) => item.settings.position.includes('TOP'))
      },

      bottoms() {
        return this.toast$.toasts.filter((item) => item.settings.position.includes('BOTTOM'))
      }
    }
  }
</script>

<style lang="sass" scoped>
  .ToastStack
    display: flex
    flex-direction: column
    padding: 20px
    position: fixed
    width: 100%
    z-index: 1500
    gap: 10px
    pointer-events: none

  .ToastStack-top
    top: 0

  .ToastStack-bottom
    bottom: 0

  .ToastStack-appear-enter-active,
  .ToastStack-appear-leave-active
    transition: all .25s

  .ToastStack-appear-enter-from, .ToastStack-appear-leave-to
    opacity: 0

  .ToastStack-top
    .ToastStack-appear-enter-from
      transform: translateY(-50px)

  .ToastStack-bottom
    .ToastStack-appear-enter-from
      transform: translateY(+50px)
</style>
