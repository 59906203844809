<template>
  <span class="AppValue AppValue--positive" v-if="colored && positive">
    +{{ before }}{{ formatDecimal(value) }}{{ after }}
  </span>

  <span class="AppValue AppValue--negative" v-if="colored && !positive">
    -{{ before }}{{ formatDecimal(Math.abs(value)) }}{{ after }}
  </span>

  <span class="AppValue" v-if="!colored">
    {{ before }}{{ formatDecimal(value) }}{{ after }}
  </span>
</template>

<script>
  import { formatDecimal } from '@/utils/number'

  export default {
    props: {
      value: {
        type: Number,
        default: 0
      },
      before: {
        type: String,
        default: ''
      },
      after: {
        type: String,
        default: ''
      },
      colored: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      positive() {
        return this.value >= 0
      }
    },

    methods: {
      formatDecimal
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AppValue
    &--positive
      color: $success

    &--negative
      color: $danger
</style>
