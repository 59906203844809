<template>
  <div>
    <LayoutPrivate v-if="layout === 'PRIVATE'" />
    <LayoutPublic v-if="layout === 'PUBLIC'" />
    <LayoutInvoice v-if="layout === 'INVOICE'" />
  </div>

  <ModalStack />
  <ToastStack />
</template>

<script>
  import LayoutPrivate from '@/components/layouts/LayoutPrivate'
  import LayoutPublic from '@/components/layouts/LayoutPublic'
  import LayoutInvoice from '@/components/layouts/LayoutInvoice'
  import ModalStack from '@/plugins/modal/ModalStack.vue'
  import ToastStack from '@/plugins/toast/ToastStack.vue'
  // import ToastStack from '@/components/base/ToastStack.vue'

  import store from '@/store'

  export default {
    components: {
      LayoutPrivate,
      LayoutPublic,
      LayoutInvoice,
      ModalStack,
      ToastStack
    },

    data: () => ({
      mini: null,
      drawer: null,
      drawerRight: null,
      right: false,
      left: false
    }),

    computed: {
      layout() {
        return this.$route.meta.layout || 'PRIVATE'
      }
    },

    created() {
      store.dispatch('auth/autoLogin')
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.sass'
</style>
