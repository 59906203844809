import { shallowReactive, ref } from 'vue'

export const defaultSettings = {
  animated: true,
  centered: true,
  closeOnBackdrop: true,
  closeOnEscape: true,
  hasBackdrop: true,
  props: {},
  scrollable: false,
  size: '',
  zIndex: 1050,
  zIndexStep: 10
}

const modals = shallowReactive([])

function generateId() {
  return Math.random().toString(36).substr(2, 9)
}

export default (globalSettings = {}) => {
  return {
    modals,

    settings: Object.assign({}, defaultSettings, globalSettings),

    open(component, modalSettings = {}) {
      const count = modals.length
      const id = generateId()
      const props = Object.assign({}, defaultSettings.props, modalSettings.props)
      const settings = Object.assign({}, defaultSettings, modalSettings)
      const shown = ref(true)

      const modal = {
        id,
        component,
        props,
        settings,
        shown,
        count,
        close: (data) => {
          this.close(id, data)
        },
        onClose(callback) {
          modal.callback = callback
        }
      }

      modals.push(modal)

      document.body.classList.add('modal-open')

      return modal
    },

    close(id, data) {
      let idx, canBeClosed = true

      idx = modals.findIndex((modal) => modal.id === id)

      const currentModal = modals[idx]

      if (currentModal) {
        if (typeof currentModal.callback === 'function') {
          canBeClosed = currentModal.callback(data)
        }

        if (canBeClosed !== false) {
          currentModal.shown.value = false

          setTimeout(() => {
            idx = modals.findIndex((modal) => modal.id === id)

            modals.splice(idx, 1)
          }, 1000)
        }
      }

      if (!modals.length) {
        document.body.classList.remove('modal-open')
      }
    },

    closeAll() {
      modals.forEach((modal) => {
        this.close(modal.id)
      })

      document.body.classList.remove('modal-open')
    },

    setSettings(newDefaultSettings) {
      Object.assign(defaultSettings, newDefaultSettings)
    }
  }
}

export function focusTrap(parent) {
  parent.focus()

  const focusables = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'details',
    'summary',
    'iframe',
    'object',
    'embed',
    '[contenteditable]'
  ]

  let index = -1

  let elmts = parent.querySelectorAll(focusables.join(','))

  elmts = Array.from(elmts).filter((elmt) => {
    return !elmt.disabled && !elmt.readonly && elmt.getAttribute('tabindex') !== '-1'
  })

  elmts.forEach((elmt, i) => {
    elmt.onfocus = () => {
      index = i
    }
  })

  elmts.sort((a, b) => {
    if (!a.getAttribute('tabindex')) {
      return 1
    }

    if (!b.getAttribute('tabindex')) {
      return -1
    }

    return a.getAttribute('tabindex') - b.getAttribute('tabindex')
  })

  parent.addEventListener('keydown', (e) => {
    if (!elmts.length) {
      return
    }

    if (e.key === 'Tab') {
      e.preventDefault()

      if (e.shiftKey) {
        index = index <= 0 ? elmts.length - 1 : index - 1
      } else {
        index = index >= elmts.length - 1 ? 0 : index + 1
      }

      elmts[index].focus()
    }
  })
}
