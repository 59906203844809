import exchangeService from '@/services/exchanges.service'

const initialState = {
  loading: false,
  exchanges: []
}

export const exchanges = {
  namespaced: true,

  state: initialState,

  actions: {
    fetch({ commit }) {
      commit('LOADING', true)

      return exchangeService.fetch()
        .then((list) => {
          commit('FETCH_SUCCESS', list)

          return list
        })
        .catch((error) => {
          commit('FETCH_FAILURE', error)

          return Promise.reject(error)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    }
  },

  mutations: {
    LOADING(state, loading) {
      state.loading = loading
    },

    FETCH_SUCCESS(state, list) {
      state.exchanges = list
    },

    FETCH_FAILURE(state) {
      state.exchanges = []
    }
  }
}
