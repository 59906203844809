import { createStore } from 'vuex'

import { auth } from './auth.module'
import { app } from './app.module'
import { exchanges } from './exchanges.module'
import { agents } from './agents.module'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    app,
    exchanges,
    agents
  }
})
