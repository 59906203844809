<template>
  <div class="modal-header">
    <h5 class="modal-title">
      Change your One Time Password
    </h5>

    <button type="button" class="btn-close" @click="modal.close()" />
  </div>

  <div class="modal-body">
    <div class="alert alert-warning" v-if="!formError">
      <strong>Hello {{ authenticated.firstname }}!</strong>
      <br>
      This is the first time you are signing in since your password has been generated.
      For security reason, please change this password.
    </div>

    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <form novalidate class="form my-4">
      <input type="text" name="email" autocomplete="username" hidden :value="authenticated.email">

      <div class="mb-3">
        <FormPassword type="password" autocomplete="new-password" label="New password" :validator="v$.form.new" hint="At least 1 uppercase, 1 lowercase, 1 digit. 8 chars min." />
      </div>

      <div class="mb-3">
        <FormPassword type="password" autocomplete="new-password" label="Confirm password" :validator="v$.form.confirmation" />
      </div>
    </form>
  </div>

  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-primary" @click="onChangePassword" :disabled="v$.form.$invalid">
      Change password
    </button>
  </div>
</template>

<script>
  import FormPassword from '@/components/base/FormPassword'

  import authService from '@/services/auth.service'

  import useToast from '@/plugins/toast/useToast'
  import useVuelidate from '@vuelidate/core'
  import { required, sameAs } from '@vuelidate/validators'
  import { passwordStrength } from '@/validators/passwordStrength'
  import { mapActions } from 'vuex'

  export default {
    components: {
      FormPassword
    },

    props: {
      modal: {
        type: Object,
        default: () => ({})
      },

      authenticated: {
        type: Object,
        default: null
      },

      password: {
        type: String,
        default: null
      }
    },

    setup() {
      return {
        v$: useVuelidate(),
        toast$: useToast()
      }
    },

    data() {
      return {
        formError: null,
        form: {
          new: '',
          confirmation: ''
        }
      }
    },

    validations() {
      return {
        form: {
          new: { required, passwordStrength },
          confirmation: { sameAsPassword: sameAs(this.form.new) }
        }
      }
    },

    methods: {
       ...mapActions('auth', [
        'getAuthenticated'
      ]),

      async onChangePassword() {
        this.v$.form.$touch()

        try {
          const response = await authService.changePassword(
            this.password,
            this.form.new
          )

          this.v$.form.$reset()

          this.form.current = ''
          this.form.new = ''
          this.form.confirmation = ''

          authService.setToken(response.auth_token)

          this.getAuthenticated()
          this.toast$.success('Well done!', 'The password has been changed.')

          this.modal.close()
        } catch (e) {
          // console.log(e)
          this.formError = 'Something went wrong on the server: ' + e.message
        }
      }
    }
  }
</script>

