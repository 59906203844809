<template>
  <div class="dropdown" v-bind="$attrs" :class="{ 'show': show, ['drop' + drop ]: true }" @click.stop="toggle">
    <slot />
    <div ref="menu" class="dropdown-menu" data-bs-popper :class="{ 'show': show, [ 'dropdown-menu-' + fixedAlign ]: true }">
      <slot name="menu" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      align: {
        type: String,
        default: 'auto'
      },
      drop: {
        type: String,
        default: 'down'
      }
    },

    data() {
      return {
        show: false,
        fixedAlign: this.align
      }
    },

    methods: {
      toggle() {
        if (this.show) {
          this.close()
        } else {
          this.open()
        }
      },

      open() {
        this.show = true
        document.body.addEventListener('click', this.close, { once: true })
        document.body.addEventListener('keydown', this.closeOnEsc, { once: true })

        if (this.align === 'auto') {
          this.$nextTick(() => {
            const windowWidth = window.innerWidth
            const box = this.$refs.menu.getBoundingClientRect()
            const menuWidth = box.left + box.width

            if (menuWidth >= windowWidth) {
              this.fixedAlign = 'end'
            }
          })
        }
      },

      close() {
        this.show = false
        document.body.removeEventListener('click', this.close)
        document.body.removeEventListener('keydown', this.closeOnEsc)
      },

      closeOnEsc(e) {
        if (e.key === 'Escape') {
          this.close()
        }
      }
    }
  }
</script>

<style lang="sass">
  .dropdown
    cursor: pointer
</style>
