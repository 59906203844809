import api from '@/shared/api/token'
import { pick } from 'lodash'

const ENDPOINT = '/agents'

class ClientService {
  fields = [
    'id',
    'account_number',
    'firstname',
    'lastname',
    'company_name',
    'email',
    'password',
    'phone',
    'address',
    'country',
    'vat',
    'special_performance_fee',
    'special_quaterly_fee'
  ]

  fetch() {
    return api.get(ENDPOINT).then((response) => {
      return response.data.data
    })
  }

  save(data) {
    if (data.id) {
      return this.update(data.id, data)
    } else {
      return this.create(data)
    }
  }

  create(data) {
    data = pick(data, this.fields)

    return api.post(ENDPOINT, data).then((response) => {
      return response.data.data
    })
  }

  update(id, data) {
    data = pick(data, this.fields)

    return api.put(ENDPOINT + '/' + id, data).then((response) => {
      return response.data.data
    })
  }

  delete(id) {
    return api.delete(ENDPOINT + '/' + id).then((response) => {
      return response.data.data
    })
  }

  regeneratePassword(id) {
    return api.put(ENDPOINT + '/' + id + '/regen-password').then((response) => {
      return response.data.data
    })
  }
}

export default new ClientService()
