import authService from '@/services/auth.service'

const initialState = {
  loading: false,
  authenticated: null,
  impersonated: authService.getImpersonated(),
  status: {}
}

export const auth = {
  namespaced: true,

  state: initialState,

  getters: {
    isAuthAdmin(state) {
      return state.authenticated && state.authenticated.admin
    },

    isAuthAgent(state) {
      return state.authenticated && state.authenticated.agent
    },

    isAuthImpersonate(state) {
      return state.impersonated
    }
  },

  actions: {
    login({ commit, dispatch }, { email, password, remember }) {
      commit('LOADING', true)

      return authService.login(email, password, remember)
        .then((authenticated) => {
          commit('LOGIN_SUCCESS', authenticated)

          dispatch('getAuthenticated')

          return authenticated
        })
        .catch((error) => {
          commit('LOGIN_FAILURE', error)

          return Promise.reject(error)
        })
        .finally(() => {
          commit('LOADING', false)
          commit('UNSET_IMPERSONATED')
        })
    },

    logout({ commit }) {
      authService.logout()

      commit('LOGOUT')
      commit('UNSET_IMPERSONATED')
    },

    autoLogin({ dispatch }) {
      if (authService.getToken()) {
        dispatch('getAuthenticated')
      }
    },

    getAuthenticated({ commit, dispatch }) {
      return authService.getAuthenticated(true /* admin */)
        .then((authenticated) => {
          commit('SET_AUTHENTICATED_SUCCESS', authenticated)

          if (authenticated.admin) {
            dispatch('agents/fetch', null, { root: true })
          }

          dispatch('exchanges/fetch', null, { root: true })

          return authenticated
        })
        .catch((error) => {
          commit('SET_AUTHENTICATED_FAILURE', error)

          return Promise.reject(error)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    },

    setImpersonated({ commit }, authenticated) {
      commit('SET_IMPERSONATED', authenticated)
    },

    unsetImpersonated({ commit }) {
      commit('UNSET_IMPERSONATED')
    }
  },

  mutations: {
    LOADING(state, loading) {
      state.loading = loading
    },

    LOGIN_SUCCESS(state, authenticated) {
      state.authenticated = authenticated
    },

    LOGIN_FAILURE(state) {
      state.authenticated = null
    },

    LOGOUT(state) {
      state.authenticated = null
    },

    SET_AUTHENTICATED_SUCCESS(state, authenticated) {
      state.authenticated = authenticated
    },

    SET_AUTHENTICATED_FAILURE(state) {
      state.authenticated = null
    },

    SET_IMPERSONATED(state, authenticated) {
      state.impersonated = authenticated
      authService.setImpersonated(authenticated)
    },

    UNSET_IMPERSONATED(state) {
      state.impersonated = null
      authService.clearImpersonated()
    }
  }
}
