<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <div class="form-check form-switch">
      <input
        type="checkbox"
        class="form-check-input"
        v-bind="$attrs"
        v-model="model"
        :id="elmtId"
        :name="name"
        :true-value="trueValue"
        :false-value="falseValue"
        :class="{ 'is-invalid': validator && validator.$error }"
        @change="onUpdateValue"
      >
      <label class="form-check-label" :for="id">
        {{ text }}
      </label>
    </div>
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      trueValue: {
        type: [ Number, Boolean, String ],
        default: true
      },
      falseValue: {
        type: [ Number, Boolean, String ],
        default: false
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: Boolean,
        default: false
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        elmtId: this.id || generateId(),
        model: null
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue
          }
        }
      }
    },

    methods: {
      onUpdateValue() {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = this.model
        }
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>
