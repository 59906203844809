<template>
  <ModalBase v-for="modal in modal$.modals" :key="modal.id" :modal="modal">
    <component
      v-bind="modal.props"
      :is="modal.component"
      :modal="modal"
    />
  </ModalBase>
</template>

<script>
  import ModalBase from './ModalBase'
  import useModal from './useModal'

  export default {
    components: {
      ModalBase
    },

    setup() {
      return {
        modal$: useModal()
      }
    }
  }
</script>
