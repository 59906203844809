import { format, formatDistance } from 'date-fns'

function utc(date) {
  return new Date(new Date(date).toUTCString().slice(0, -3))
}

export function formatDate(date, string = 'dd/MM/yyyy') {
  date = utc(date)

  try {
    return format(date, string)
  } catch (e) {
    return ''
  }
}

export function formatRelativeDate(from) {
  try {
    return formatDistance(new Date(from), new Date(), { addSuffix: true })
  } catch (e) {
    return ''
  }
}

export function formatDuration(ms) {
  const from = new Date(ms).getTime() / 1000
  const to = new Date().getTime() / 1000

  const duration = to - from

  let delta = Math.abs(duration)

  const days = Math.floor(delta / 86400)

  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24

  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60

  delta -= minutes * 60

  const seconds = Math.floor(delta % 60)

  const time = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds)

  return (days ? days + 'd ' : '') + time
}
