<template>
  <div
    class="LayoutPrivate layout"
    :class="{
      'sidebar-is-minified': isSidebarMinified,
      'sidebar-is-open': isSidebarOpen,
    }"
  >
    <div class="layout-side">
      <AppSidebar />
    </div>

    <div class="layout-main">
      <AppHeader />

      <main class="layout-view">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
  import AppHeader from '@/components/AppHeader'
  import AppSidebar from '@/components/AppSidebar'
  import { mapState } from 'vuex'

  export default {
    components: {
      AppHeader,
      AppSidebar
    },
    computed: {
      ...mapState('app', [
        'isSidebarMinified',
        'isSidebarOpen'
      ])
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.vars.all'

  body
    overflow: hidden

  .LayoutPrivate
    display: flex
    position: relative
    transform: translateX(-$sidebar-width - 2)
    width: calc(100% + #{$sidebar-width})
    transition: .25s transform, background .25s

    &.sidebar-is-open
      transform: translateX(0)

    @include media-breakpoint-up(sm)
      transform: translateX(0)
      width: 100vw

    .layout-side
      flex: 0 0 auto
      border: 1px solid #000

    .layout-main
      flex: 1 1 auto
      width: calc(100% - #{$sidebar-width})

    .layout-view
      height: calc(100vh - #{$header-height})
      overflow-y: scroll
      padding: $spacer $spacer-1

      @include media-breakpoint-up(md)
        padding: $spacer-5 $spacer-4

</style>
