<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <div
      class="input-group"
      :class="{ 'is-invalid': validator && validator.$error }"
    >
      <input
        class="form-control"
        autocomplete="off"
        v-bind="$attrs"
        v-model="model"
        :type="type"
        :id="elmtId"
        :name="name"
        :placeholder="placeholder"
        :class="[ 'form-control-' + size, { 'is-invalid': validator && validator.$error } ]"
        @input="onUpdateValue"
      >
      <div class="input-group-text">
        <button class="btn p-0" type="button" tabindex="-1" @click="onToggle">
          <i v-if="type === 'password'" class="bi bi-eye" />
          <i v-if="type === 'text'" class="bi bi-eye-slash" />
        </button>
      </div>
    </div>
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: String,
        default: ''
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        elmtId: this.id || generateId(),
        model: null,
        type: 'password'
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue
          }
        }
      }
    },

    methods: {
      onToggle() {
        this.type = this.type === 'password' ? 'text' : 'password'
      },

      onUpdateValue() {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = this.model
        }
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .input-group-text
    min-width: 50px
    text-align: center
    display: inline-block
    outline: none
</style>
