const CULTURE = 'fr-FR'

export function padNumber(number, nb, symbol = '0') {
  return String(number).padStart(nb, symbol)
}

export function formatNumber(number, options = {
  integer: 1,
  decimal: 0
}) {
  if (typeof number === 'undefined' || number === null) {
   return 0
  }

  return new Intl.NumberFormat(CULTURE, {
    style: 'decimal',
    minimumIntegerDigits: options.integer,
    minimumFractionDigits: options.decimal,
    maximumFractionDigits: options.decimal
  }).format(number)
}

export function formatDecimal(number, options = {
  integer: 1,
  decimal: 2
}) {
  if (typeof number === 'undefined' || number === null) {
    return 0
  }

  return new Intl.NumberFormat(CULTURE, {
    style: 'decimal',
    minimumIntegerDigits: options.integer,
    minimumFractionDigits: options.decimal,
    maximumFractionDigits: options.decimal
  }).format(number)
}
