<template>
  <div class="ClientAccountChart">
    <div class="ClientAccountChart__balance">
      ${{ formatDecimal(balance) }}
    </div>

    <div class="ClientAccountChart__chart">
      <AppChart type="line" :data="data" :options="options" v-bind="$props" />
    </div>
  </div>
</template>

<script>
  import AppChart from '@/components/base/AppChart'

  import theTheme from '@/theme.js'
  import { hexToRGB } from '@/utils/color'
  import { formatDecimal } from '@/utils/number'

  export default {
    components: {
      AppChart
    },

    props: {
      account: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        data: null,
        options: null,
        balance: 0
      }
    },

    watch: {
      account: {
        immediate: true,
        handler(response) {
          this.prepareData(response)
        }
      }
    },

    methods: {
      formatDecimal,

      async prepareData(response) {
        if (!response || !response.chart) {
          return
        }

        const theme = await theTheme.ready()
        const balance = response.balance
        const points = response.chart
        const data = []

        this.balance = balance

        points.forEach((point) => {
          data.push({ x: new Date(point[0]).toISOString(), y: point[1] })
        })

        this.data = {
          datasets: [
            {
              data: data,
              borderWidth: 2,
              pointRadius: 2,
              fill: true,
              backgroundColor: hexToRGB('#FFF', 0.5),
              borderColor: theme.secondary,
              pointBackgroundColor: theme.secondary,
              pointBorderColor: theme.secondary,
              pointBorderWidth: 0,
              pointWidth: 5,
              cubicInterpolationMode: 'monotone'
            }
          ]
        }

        this.options = {
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month'
              }
            }
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .ClientAccountChart
    position: relative

  .ClientAccountChart__balance
    font-size: 2em
    font-weight: bold
    margin-bottom: $spacer
    color: $primary

  .ClientAccountChart__chart
    height: 300px
</style>
