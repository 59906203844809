import agentService from '@/services/agents.service'

const initialState = {
  loading: false,
  agents: []
}

export const agents = {
  namespaced: true,

  state: initialState,

  actions: {
    updateList({ commit }, list) {
      commit('FETCH_SUCCESS', list)
    },

    fetch({ commit }) {
      commit('LOADING', true)

      return agentService.fetch()
        .then((list) => {
          commit('FETCH_SUCCESS', list)

          return list
        })
        .catch((error) => {
          commit('FETCH_FAILURE', error)

          return Promise.reject(error)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    }
  },

  mutations: {
    LOADING(state, loading) {
      state.loading = loading
    },

    FETCH_SUCCESS(state, list) {
      state.agents = list
    },

    FETCH_FAILURE(state) {
      state.agents = []
    }
  }
}
