<template>
  <div v-if="errors.length" class="invalid-feedback">
    <div v-for="error in errors" :key="error">
      {{ getTextMessage(error) }}
    </div>
  </div>
</template>

<script>
  import { validationTexts } from '@/settings'

  export default {
    props: {
      v: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        errors: []
      }
    },

    watch: {
      v: {
        immediate: false,
        deep: true,
        handler(v) {
          this.errors = []

          if (v.$error) {
            Object.keys(v).forEach((key) => {
              if (!key.startsWith('$') && v[key].$invalid) {
                this.errors.push(key)
              }
            })
          }
        }
      }
    },

    methods: {
      getTextMessage(key) {
        return validationTexts[key] || key
      }
    }
  }
</script>
