<template>
  <svg viewBox="0 0 74 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="logo" fill="#0f1537" fill-rule="nonzero">
        <path d="M22.11,40.25 C12.35,40.25 0,33.85 0,20.12 C0,6.39 12.35,0 22.11,0 L26.3,0 L26.3,1.23 C16.42,3.05 9.3,10.93 9.3,20.12 C9.3,29.31 16.43,37.2 26.3,39.02 L26.3,40.25 L22.11,40.25 Z" id="Path" />
        <path d="M50,0 L31.57,0 L31.57,1.23 C41.25,3.01 48.26,10.61 48.57,19.56 L50.02,19.56 L50,0 Z" id="Path" />
        <path d="M39.04,13.04 L38.22,13.04 C37.96,14.63 37.99,15.93 36.22,17.69 C34.45,19.45 33.15,19.43 31.56,19.69 L31.56,20.56 C33.15,20.82 34.45,20.79 36.22,22.56 C37.99,24.33 37.95,25.63 38.22,27.22 L39.04,27.22 L39.04,20.52 L39.04,20.52 L39.04,13.04 Z" id="Path" />
        <path d="M73.75,0 L55.27,0 L55.27,1.23 C64.95,3.01 71.97,10.61 72.27,19.56 L73.75,19.56 L73.75,0 Z" id="Path" />
        <path d="M55.75,32.74 L54.89,32.74 C54.63,34.33 54.65,35.63 52.89,37.4 C51.13,39.17 49.83,39.13 48.23,39.4 L48.23,40.22 L62.41,40.22 L62.41,39.4 C60.81,39.14 59.51,39.17 57.75,37.4 C55.99,35.63 55.96,34.37 55.75,32.74" id="Path" />
      </g>
    </g>
  </svg>
</template>
