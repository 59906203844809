<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.7 93.54">
    <path class="cls-1" d="M53.36,100l-1,.7a2.11,2.11,0,0,0-1.85-1.08,2.12,2.12,0,0,0,0,4.24,2.1,2.1,0,0,0,1.85-1.09l1,.71a3.22,3.22,0,0,1-2.86,1.6,3.35,3.35,0,1,1,0-6.69A3.22,3.22,0,0,1,53.36,100" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M58.73,101.49h1.11a.93.93,0,1,0,0-1.85H58.73Zm2.64,3.44-1.67-2.28h-1v2.28H57.41V98.48h2.43a2.1,2.1,0,0,1,2.32,2.08A2,2,0,0,1,61,102.39l1.83,2.54Z" transform="translate(-40.25 -40.26)" />
    <polygon class="cls-1" points="28.98 62.13 28.98 64.68 27.66 64.68 27.66 62.15 25.43 58.23 26.89 58.23 28.31 60.79 29.74 58.23 31.2 58.23 28.98 62.13" />
    <path class="cls-1" d="M76.61,99.64v1.86h1.11a.93.93,0,1,0,0-1.86Zm3.43.92a2.12,2.12,0,0,1-2.32,2.09H76.61v2.28H75.29V98.48h2.43A2.1,2.1,0,0,1,80,100.56" transform="translate(-40.25 -40.26)" />
    <polygon class="cls-1" points="48.62 59.42 46.55 59.42 46.55 64.68 45.23 64.68 45.23 59.42 43.17 59.42 43.17 58.23 48.62 58.23 48.62 59.42" />
    <path class="cls-1" d="M93.52,101.71a2.08,2.08,0,1,0,2.07-2.12,2,2,0,0,0-2.07,2.12m5.5,0a3.43,3.43,0,1,1-3.44-3.35A3.36,3.36,0,0,1,99,101.71" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M107.22,99.59l-.89.69a1.45,1.45,0,0,0-1.24-.74c-.43,0-.8.19-.8.62s.29.53.75.74l.74.33c1,.45,1.59,1,1.59,1.88a2,2,0,0,1-2.26,1.94,2.79,2.79,0,0,1-2.49-1.43l.91-.68a1.89,1.89,0,0,0,1.58.94c.57,0,.92-.27.92-.72s-.31-.56-.82-.8l-.69-.31c-.92-.42-1.57-1-1.57-1.86a1.92,1.92,0,0,1,2.14-1.83,2.31,2.31,0,0,1,2.13,1.23" transform="translate(-40.25 -40.26)" />
    <polygon class="cls-1" points="11.43 73.79 8.51 73.79 8.51 75.41 10.9 75.41 10.9 76.6 8.51 76.6 8.51 79.05 7.19 79.05 7.19 72.6 11.43 72.6 11.43 73.79" />
    <path class="cls-1" d="M61,116.64a2.74,2.74,0,1,1-5.47,0v-3.78h1.32v3.88a1.42,1.42,0,1,0,2.83,0v-3.88H61Z" transform="translate(-40.25 -40.26)" />
    <polygon class="cls-1" points="30.62 72.6 30.62 79.17 30.18 79.17 26.45 75.18 26.45 79.05 25.13 79.05 25.13 72.48 25.56 72.48 29.3 76.44 29.3 72.6 30.62 72.6" />
    <path class="cls-1" d="M76.6,114.05v4.06h.78a2,2,0,0,0,2.17-2,2,2,0,0,0-2.17-2Zm4.31,2a3.25,3.25,0,0,1-3.52,3.25H75.28v-6.45h2.11a3.2,3.2,0,0,1,3.52,3.2" transform="translate(-40.25 -40.26)" />
    <polygon class="cls-1" points="6.58 86.97 6.58 88.17 8.65 88.17 8.65 93.42 9.96 93.42 9.96 88.17 12.04 88.17 12.04 86.97 6.58 86.97" />
    <path class="cls-1" d="M57.41,130.24h1.11a.93.93,0,1,0,0-1.85H57.41Zm2.64,3.44-1.67-2.28h-1v2.28H56.09v-6.45h2.43a2.11,2.11,0,0,1,2.33,2.08,2,2,0,0,1-1.14,1.83l1.83,2.54Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M67.77,129.59l.85,2.16h-1.7Zm-.22-2.48-2.88,6.57h1.42l.36-.84h2.64l.36.84h1.41L68,127.11Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M76,128.42v4.07h.78A2,2,0,0,0,79,130.44a2,2,0,0,0-2.17-2Zm4.31,2a3.25,3.25,0,0,1-3.52,3.24H74.68v-6.45h2.11a3.2,3.2,0,0,1,3.52,3.21" transform="translate(-40.25 -40.26)" />
    <rect class="cls-1" x="44.1" y="86.98" width="1.32" height="6.45" />
    <polygon class="cls-1" points="49.83 86.86 49.83 93.42 51.15 93.42 51.15 89.56 54.88 93.54 55.32 93.54 55.32 86.97 54 86.97 54 90.81 50.26 86.86 49.83 86.86" />
    <path class="cls-1" d="M105.78,128.51l-.94.77a2.13,2.13,0,0,0-1.8-.95,2,2,0,0,0-2.07,2.13,2.05,2.05,0,0,0,2.15,2.16,2,2,0,0,0,1.7-.81v-.52H103v-1.15h3.1v1.91a3.49,3.49,0,0,1-3.08,1.75,3.35,3.35,0,1,1,0-6.69,3.22,3.22,0,0,1,2.72,1.4" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M62.36,80.51c-9.76,0-22.11-6.4-22.11-20.13S52.6,40.26,62.36,40.26h4.19v1.23c-9.88,1.82-17,9.7-17,18.89s7.13,17.08,17,18.9v1.23Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M90.25,40.26H71.82v1.23c9.68,1.78,16.69,9.38,17,18.33h1.45Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M79.29,53.3h-.82c-.26,1.59-.23,2.89-2,4.65s-3.07,1.74-4.66,2v.87c1.59.26,2.89.23,4.66,2s1.73,3.07,2,4.66h.82v-6.7h0V53.3Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M114,40.26H95.52v1.23c9.68,1.78,16.7,9.38,17,18.33H114Z" transform="translate(-40.25 -40.26)" />
    <path class="cls-1" d="M96,73h-.86c-.26,1.59-.24,2.89-2,4.66s-3.06,1.73-4.66,2v.82h14.18v-.82c-1.6-.26-2.9-.23-4.66-2S96.21,74.63,96,73" transform="translate(-40.25 -40.26)" />
  </svg>
</template>
