<template>
  <div class="has-validation">
    <div class="form-group" v-if="!floating">
      <label class="form-label" v-if="label" :for="id">{{ label }}</label>
      <slot />
      <FormErrors v-if="validator" :v="validator" />

      <small v-if="hint" class="form-text">
        {{ hint }}
      </small>
    </div>

    <div class="form-floating" v-if="floating">
      <slot />
      <label class="form-label" v-if="label" :for="id">{{ label }}</label>
      <FormErrors v-if="validator" :v="validator" />

      <small v-if="hint" class="form-text">
        {{ hint }}
      </small>
    </div>
  </div>
</template>

<script>
  import FormErrors from '@/components/base/FormErrors'

  export default {
    components: {
      FormErrors
    },

    props: {
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      validator: {
        type: Object,
        default: null
      },
      floating: {
        type: Boolean,
        default: false
      }
    },

    watched: {
      floating: {
        immediate: true,
        handler() {
          if (this.floating === true && !this.placehomder) {
            this.placehomder = '  '
          }
        }
      }
    }
  }
</script>
