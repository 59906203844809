<template>
  <div
    v-if="modal.settings.hasBackdrop"
    class="modal-backdrop"
    :style="{ zIndex: zIndex }"
    :class="{
      'show': modal.shown.value && shown,
      'fade': modal.settings.animated
    }"
  />

  <div
    aria-modal="true"
    role="dialog"
    ref="modal"
    class="modal"
    tabindex="-1"
    :style="{ zIndex: zIndex }"
    :class="{
      'show': modal.shown.value && shown,
      'fade': modal.settings.animated
    }"
    @keydown.esc="modal.settings.closeOnEscape && modal.close()"
    @click.self="modal.settings.closeOnBackdrop && modal.close()"
  >
    <div
      class="modal-dialog"
      role="document"
      :class="{
        'modal-dialog-centered': modal.settings.centered,
        'modal-dialog-scrollable': modal.settings.scrollable,
        ['modal-' + modal.settings.size]: true
      }"
    >
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import { focusTrap } from '@/plugins/modal/useModal'

  export default {
    props: {
      modal: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      const modal = this.modal
      const zIndex = modal.settings.zIndex + modal.count * modal.settings.zIndexStep

      return {
        zIndex,
        shown: false
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.shown = true

        this.$refs.modal.focus()

        this.focusTrap(this.$refs.modal)
      })
    },

    methods: {
      focusTrap
    }
  }
</script>

<style lang="sass" scoped>
  .modal
    display: block
</style>
