export default {
  componentUpdated: function (el, binding) {
    const validator = binding.value

    if (validator.$error) {
      el.classList.add('is-invalid')
    } else {
      el.classList.remove('is-invalid')
    }
  }
}
