<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <input
      class="form-control"
      v-bind="$attrs"
      v-model="model"
      :id="elmtId"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :class="[ 'form-control-' + size, { 'is-invalid': validator && validator.$error, 'text-end': right === true } ]"
      @input="onUpdateValue"
    >
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      placeholder: {
        type: String,
        default: ''
      },
      right: {
        type: Boolean,
        default: false
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: [ Number, String ],
        default: ''
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        elmtId: this.id || generateId(),
        model: null
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue
          }
        }
      }
    },

    methods: {
      onUpdateValue() {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = this.model
        }
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>
