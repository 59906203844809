import api from '@/shared/api/token'
import { exchanges } from '@/settings'

import { sortBy } from 'lodash'

const ENDPOINT = '/exchanges'

class ExchangeService {
  fetch() {
    return api.get(ENDPOINT).then((response) => {
      const obj = {}

      let list = response.data.data

      list = sortBy(list, 'name')

      list = list.map((item) => {
        if (exchanges[item.key]) {
          return {
            ...exchanges[item.key],
            ...item
          }
        }

        return item
      })

      list.forEach((item) => {
        obj[item.key] = item
      })

      return obj
    })
  }
}

export default new ExchangeService()
