// import axios from 'axios'
import Router from '@/router'
import api from '@/shared/api'
import apiWithToken from '@/shared/api/token'
import { superLocalStorage, superSessionStorage } from '@/utils/storage'

const ENDPOINT = '/login'
const ENDPOINT_ME = '/me'

class AuthService {
  setToken(token, remember = false) {
    this.clearToken()
    this.clearImpersonated()

    if (remember) {
      superLocalStorage.setItem('token', token)
    } else {
      superSessionStorage.setItem('token', token)
    }
  }

  getToken() {
    return superLocalStorage.getItem('token') || superSessionStorage.getItem('token')
  }

  clearToken() {
    superSessionStorage.removeItem('token')
    superLocalStorage.removeItem('token')
  }

  setImpersonated(client) {
    superSessionStorage.setItem('impersonated', client)
  }

  getImpersonated() {
    return superSessionStorage.getItem('impersonated')
  }

  clearImpersonated() {
    superSessionStorage.removeItem('impersonated')
  }

  login(email, password, remember = false) {
    return api.post(ENDPOINT, {
      email,
      password
    })
    .then((response) => {
      if (response.data.data.auth_token) {
        this.setToken(response.data.data.auth_token, remember)
      }

      return response.data.data
    })
  }

  logout() {
    this.clearToken()
    this.clearImpersonated()
    Router.push({ name: 'auth-login' }).catch(() => {})
  }

  getAuthenticated(authenticatedNotImpersonated = false) {
    return apiWithToken.get(ENDPOINT_ME, {
      authenticatedNotImpersonated
    })
      .then((response) => {
        return response.data.data
      })
  }

  changePassword(currentPassword, newPassword) {
    return apiWithToken.post(ENDPOINT_ME + '/password', {
      old: currentPassword,
      new: newPassword
    }, {
      doNotInterceptStatuses: [ 403 ]
    })
      .then((response) => {
        return response.data.data
      })
  }
}

export default new AuthService()
