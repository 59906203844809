import { createRouter, createWebHistory } from 'vue-router'
import authService from '@/services/auth.service'
import store from '@/store/'

import DashboardView from '@/views/DashboardView.vue'
import AuthLoginView from '@/views/AuthLoginView.vue'

const routes = [
  {
    component: DashboardView,
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    meta: { title: 'Dashboard' }
  },
  {
    component: () => import('../views/KitchenSinkView.vue'),
    path: '/kitchen-sink',
    name: 'app-kitchen-sink',
    meta: { title: 'Bootstrap / components kitchen sink' }
  },
  {
    component: () => import('../views/AccountView.vue'),
    path: '/account',
    name: 'account',
    meta: { title: 'My account' }
  },
  {
    component: () => import('../views/ClientsView.vue'),
    path: '/clients',
    name: 'clients',
    meta: { title: 'Clients' }
  },
  {
    component: () => import('../views/AgentsView.vue'),
    path: '/agents',
    name: 'agents',
    meta: { title: 'Agents' }
  },
  {
    component: () => import('../views/TransfersView.vue'),
    path: '/transfers/',
    name: 'transfers',
    meta: { title: 'Transfers' }
  },
  {
    component: () => import('../views/AumsView.vue'),
    path: '/aum/',
    name: 'aums',
    meta: { title: 'AUM' }
  },
  {
    component: () => import('../views/AllocationsView.vue'),
    path: '/allocations/',
    name: 'allocations',
    meta: { title: 'Allocations' }
  },
  {
    component: () => import('../views/AllocationDetailsView.vue'),
    path: '/allocations/:id',
    name: 'allocationDetails',
    meta: { title: 'Allocation' }
  },
  {
    component: () => import('../views/InvoicesView.vue'),
    path: '/invoices/',
    name: 'invoices',
    meta: { title: 'Invoices' }
  },
  {
    component: () => import('../views/InvoiceDetailsView.vue'),
    path: '/invoices/:id',
    name: 'invoiceDetails',
    meta: {
      title: 'Invoice',
      layout: 'INVOICE'
    }
  },
  {
    component: () => import('../views/AccountingView.vue'),
    path: '/accounting',
    name: 'accounting',
    meta: { title: 'Accounting' }
  },
  {
    component: () => import('../views/AccountingDetailsView.vue'),
    path: '/accounting/invoices/:year/:month',
    name: 'accountingDetails',
    meta: {
      title: 'Accounting',
      layout: 'INVOICE'
    }
  },
  {
    component: () => import('../views/SettingsView.vue'),
    path: '/settings/',
    name: 'settings',
    meta: { title: 'Settings' }
  },

  // PUBLIC

  {
    component: AuthLoginView,
    path: '/login',
    name: 'auth-login',
    meta: {
      title: 'Sign in',
      public: true,
      layout: 'PUBLIC'
    }
  },

  {
    path: '/:catchAll(.*)',
    redirect: '/errors/404'
  }
]

// ERROR PAGES

const errors = [ '404', '500', 'network' ]

errors.forEach((code) => {
  routes.push({
    component: () => import('../views/ErrorView.vue'),
    path: '/errors/' + code,
    name: 'error-' + code,
    props: { error: code },
    meta: {
      title: 'Error',
      public: true,
      layout: 'PUBLIC'
    }
  })
})

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  history: createWebHistory(),
  routes
})

// Close sidebar

router.beforeEach(() => {
  store.dispatch('app/closeSidebar')
})

// Chnage title and description

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  document.querySelector('meta[name=description]').content = to.meta.description
  next()
})

// Redirect to login if not logged

router.beforeEach((to, from, next) => {
  if (to.meta.public === true) {
    return next()
  }

  if (to.name !== 'auth-login' && !authService.getToken()) {
    return next({ name: 'auth-login' })
  }

  return next()
})

// Replace URL when redirecting to error pages

router.afterEach((to, from) => {
  if (to.fullPath.includes('/errors/') && !from.fullPath.includes('/errors/')) {
    history.replaceState({}, null, from.fullPath)
  }
})

export default router
