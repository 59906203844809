<template>
  <div class="ExchangeLogo" v-if="exchange">
    <img :src="logo" v-if="logo">
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      exchange: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        logo: ''
      }
    },

    computed: {
      ...mapState('exchanges', [ 'exchanges' ])
    },

    watch: {
      exchange: {
        immediate: true,
        handler(value) {
          if (this.exchanges[value]) {
            this.logo = this.exchanges[value].logo
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  .ExchangeLogo
    object-fit: contain
</style>
