import { shallowReactive } from 'vue'

export const defaultSettings = {
  delay: 5000,
  animation: true,
  position: 'TOP-LEFT'
}

const toasts = shallowReactive([])

function generateId() {
  return Math.random().toString(36).substr(2, 9)
}

export default (globalSettings = {}) => {
  return {
    toasts,

    settings: Object.assign({}, defaultSettings, globalSettings),

    open(type, title, message, toastSettings = {}) {
      const count = toasts.length
      const id = generateId()
      const settings = Object.assign({}, defaultSettings, toastSettings)

      let props = Object.assign({}, defaultSettings.props, toastSettings.props)

      props = { ...props, type, title, message }

      const toast = {
        id,
        type,
        props,
        settings,
        count,
        close: (data) => {
          this.close(id, data)
        },
        onClose(callback) {
          toast.callback = callback
        }
      }

      toasts.push(toast)

      if (settings.delay) {
        setTimeout(() => {
          toast.close()
        }, settings.delay)
      }

      document.body.classList.add('toast-open')

      return toast
    },

    message(title, message, options = {}) {
      return this.open('DEFAULT', title, message, options)
    },

    success(title, message, options = {}) {
      return this.open('SUCCESS', title, message, options)
    },

    info(title, message, options = {}) {
      return this.open('INFO', title, message, options)
    },

    danger(title, message, options = {}) {
      return this.open('DANGER', title, message, options)
    },

    warning(title, message, options = {}) {
      return this.open('WARNING', title, message, options)
    },

    close(id, data) {
      let canBeClosed = true

      const idx = toasts.findIndex((toast) => toast.id === id)

      const currentToast = toasts[idx]

      if (toasts[idx]) {
        if (typeof currentToast.callback === 'function') {
          canBeClosed = currentToast.callback(data)
        }

        if (canBeClosed !== false) {
          toasts.splice(idx, 1)
        }
      }

      if (!toasts.length) {
        document.body.classList.remove('toast-open')
      }
    },

    closeAll() {
      toasts.forEach((toast) => {
        this.close(toast.id)
      })

      document.body.classList.remove('toast-open')
    },

    setSettings(newDefaultSettings) {
      Object.assign(defaultSettings, newDefaultSettings)
    }
  }
}
