import { superLocalStorage } from '@/utils/storage'

const initialState = {
  isSidebarOpen: false,
  isSidebarMinified: superLocalStorage.getItem('isSidebarMinified')
}

export const app = {
  namespaced: true,

  state: initialState,

  actions: {
    closeSidebar(store) {
      store.commit('CLOSE_SIDEBAR')
    },

    toggleSidebar(store) {
      store.commit('TOGGLE_SIDEBAR')
    },

    toggleSidebarMinified(store) {
      store.commit('TOGGLE_SIDEBAR_MINIFIED')
    }
  },

  mutations: {
    CLOSE_SIDEBAR(state) {
      state.isSidebarOpen = false
    },

    TOGGLE_SIDEBAR(state) {
      state.isSidebarOpen = !state.isSidebarOpen
    },

    TOGGLE_SIDEBAR_MINIFIED(state) {
      state.isSidebarMinified = !state.isSidebarMinified

      superLocalStorage.setItem('isSidebarMinified', state.isSidebarMinified)
    }
  }
}

