import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import focus from '@/directives/focus'
import invalidClass from '@/directives/invalid-class'

createApp(App)
  .use(store)
  .use(router)
  .directive('focus', focus)
  .directive('invalid-class', invalidClass)
  .mount('#app')
