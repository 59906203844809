import Router from '@/router'
import authService from '@/services/auth.service'

export default function (error) {
  if (!error.response) {
    Router.replace({ name: 'error-network' })

    return Promise.reject(error.message)
  }

  if (error.config.doNotInterceptStatuses && error.config.doNotInterceptStatuses.includes(error.response.status)) {
    return Promise.reject(error.response)
  }

  switch (error.response.status) {
    case 401:
    case 403:
      authService.logout()
    break

    case 404:
      Router.replace({ name: 'error-404' })
    break

    case 500:
      Router.replace({ name: 'error-500' })
    break

    default:
  }

  return Promise.reject(error.response)
}
