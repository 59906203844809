<template>
  <div role="alert" aria-live="assertive" aria-atomic="true" :class="computeClass()">
    <div class="toast show">
      <div class="toast-header">
        <div class="toast-square" />
        <strong class="me-auto">{{ title }}</strong>
        <button v-if="closable" type="button" class="btn-close" aria-label="Close" @click="toast.close" />
      </div>
      <div class="toast-body" v-if="message">
        <div>{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      toast: {
        type: Object,
        default() {
          return {}
        }
      },
      closable: {
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      message: {
        type: String,
        default: ''
      }
    },

    methods: {
      computeClass() {
        const type = this.toast.type.toLowerCase()
        const position = this.toast.settings.position.toLowerCase()
        const top = position.includes('top') ? 'top' : 'bottom'
        const placement = position.replace(/top|bottom|-/g, '')
        const classes = {}

        classes['toast-' + type] = true
        classes['toast-' + (placement || 'center')] = true
        classes['toast-' + top] = true

        return classes
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all'

  .toast-header
    display: flex
    align-items: center

  .toast-body
    display: flex

    div
      flex: 1 1

  .toast-square
    width: 1.5em
    height: 1.5em
    border-radius: 4px
    margin-right: $spacer-2
    vertical-align: middle

    .toast-default &
      background: $primary

    @each $state, $value in $theme-colors
     .toast-#{$state} &
      background: $value

  .toast-left
    align-self: flex-start

  .toast-center
    align-self: center

  .toast-right
    align-self: flex-end
</style>
