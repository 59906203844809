
export default class Theme {
  constructor(schema, parent) {
    return this.init(schema, parent)
  }

  init(schema = {}, parent) {
    window.addEventListener('DOMContentLoaded', () => {
      for (const [ key, varName ] of Object.entries(schema)) {
        if (Array.isArray(varName)) {
          this[key] = varName[1](this.get(varName[0], parent))
        } else {
          this[key] = this.get(varName, parent)
        }
      }
    })

    return this
  }

  set(key, value, parent = document.querySelector(':root')) {
    parent.style.setProperty(this.key(key), value)
  }

  get(key, parent = document.querySelector(':root')) {
    return getComputedStyle(parent).getPropertyValue(this.key(key)).trim()
  }

  ready() {
    return new Promise((resolve) => {
      if (document.readyState !== 'loading') {
        resolve(this)
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          resolve(this)
        })
      }
    })
  }

  key(key) {
    return key.startsWith('--') ? key : '--' + key
  }
}
