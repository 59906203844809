import { SuperLocalStorage, SuperSessionStorage } from '@jaysalvat/super-storage'

const settings = {
  storagePrefix: 'cft'
}

const superLocalStorage = new SuperLocalStorage(settings)
const superSessionStorage = new SuperSessionStorage(settings)

export {
  superLocalStorage,
  superSessionStorage
}
