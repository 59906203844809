<template>
  <main class="AuthLoginView">
    <div class="container">
      <header>
        <IconLogoFull class="AuthLoginView__logo" />
      </header>

      <div class="alert alert-danger" v-if="message">
        {{ message }}
      </div>

      <form novalidate @submit.prevent="onSubmit" class="form">
        <div class="mb-3">
          <FormInput label="Email" name="email" :validator="v$.form.email" autocomplete="username" />
        </div>

        <div class="mb-3">
          <FormPassword label="Password" name="password" autocomplete="current-password" :validator="v$.form.password" />
        </div>

        <div class="mb-3 d-flex justify-content-between">
          <div>
            <FormSwitch v-model="form.remember" text="Remember me" />
          </div>
          <!--
            <div>
            <router-link class="text-secondary" :to="{ name: 'auth-reset-email' }">
              Forgot password?
            </router-link>
          </div>
          -->
        </div>

        <div class="AuthLoginView__button">
          <button class="btn btn-primary" :disabled="v$.form.$invalid">
            Sign in
          </button>
        </div>

        <!-- <ModalPasswordForm /> -->

        <!--
        <router-link class="text-secondary" :to="{ name: 'auth-register' }">
          Don't have an account yet?
        </router-link>
        -->
      </form>
    </div>
  </main>
</template>

<script>
  import IconLogoFull from '@/components/IconLogoFull'
  import FormInput from '@/components/base/FormInput'
  import FormPassword from '@/components/base/FormPassword'
  import FormSwitch from '@/components/base/FormSwitch'

  import ModalPasswordForm from '@/components/ModalPasswordForm'

  import useModal from '@/plugins/modal/useModal'
  import useVuelidate from '@vuelidate/core'
  import { required } from '@vuelidate/validators'
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {
      IconLogoFull,
      FormInput,
      FormPassword,
      FormSwitch
    },

    setup() {
      return {
        v$: useVuelidate(),
        modal$: useModal()
      }
    },

    data() {
      return {
        message: '',
        form: {
          email: '',
          password: '',
          remember: false
        }
      }
    },

    validations() {
      return {
        form: {
          email: { required },
          password: { required }
        }
      }
    },

    computed: {
      ...mapState('auth', [ 'authenticated' ])
    },

    methods: {
      ...mapActions('auth', [ 'login' ]),

      onSubmit() {
        this.v$.form.$touch()

        this.login({
          email: this.form.email,
          password: this.form.password,
          remember: this.form.remember
        }
        ).then((response) => {
          if (response.otp) {
            this.modal$
              .open(ModalPasswordForm, {
                size: 'md',
                closeOnBackdrop: false,
                closeOnEsc: false,
                props: {
                  password: this.form.password,
                  authenticated: response
                }
              })
              .onClose(() => {
                this.$router.push({ name: 'dashboard' })
              })
          } else if (response.admin) {
            this.$router.push({ name: 'clients' })
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        }).catch((error) => {
          this.message = error.data.message
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AuthLoginView > .container
    max-width: 400px

  .AuthLoginView__logo
    width: 100px
    display: block
    margin-left: -.75em
    margin-bottom: $spacer-4

    :deep(*)
      fill: $tertiary

  .AuthLoginView__button
    margin-top: $spacer-4
</style>
