import api from '@/shared/api/token'
import { pick } from 'lodash'

const ENDPOINT = '/allocations'

class AllocationService {

  fields = [
    'id',
    'name',
    'exchange',
    'inception_balance',
    'inception_date',
    'position',
    'api',
    'secret',
    'api_extra_param'
  ]

  fetchDashboard(force) {
    return api.get('/dashboard', {
      params: { force_reload: force }
    }).then((response) => {
      return response.data.data
    })
  }

  fetchHistory(id) {
    return api.get(ENDPOINT + '/' + id + '/history').then((response) => {
      return response.data.data
    })
  }

  fetch() {
    return api.get(ENDPOINT).then((response) => {
      return response.data.data
    })
  }

  get(id) {
    return api.get(ENDPOINT + '/' + id).then((response) => {
      return response.data.data
    })
  }

  save(data) {
    if (data.id) {
      return this.update(data.id, data)
    } else {
      return this.create(data)
    }
  }

  create(data) {
    data = pick(data, this.fields)

    return api.post(ENDPOINT, data).then((response) => {
      return response.data
    })
  }

  update(id, data) {
    data = pick(data, this.fields)

    return api.put(ENDPOINT + '/' + id, data).then((response) => {
      return response.data
    })
  }

  delete(id) {
    return api.delete(ENDPOINT + '/' + id).then((response) => {
      return response.data
    })
  }

  closeMarket(id) {
    return api.put(ENDPOINT + '/' + id + '/market_close', {
    }).then((response) => {
      return response.data
    })
  }

  savePositions(allocations) {
    const positions = allocations.map((item) => {
      return item.id
    })

    return api.put(ENDPOINT + '/order', positions).then((response) => {
      return response.data
    })
  }

  reset(id) {
    return api.put(ENDPOINT + '/' + id + '/reset').then((response) => {
      return response.data.data
    })
  }
}

export default new AllocationService()
