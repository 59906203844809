<template>
  <div>
    <router-link v-if="to" class="dropdown-item" :class="{ active: active, disabled: disabled }" v-bind="$attrs" @click.stop="click" :to="to">
      <slot />
    </router-link>

    <a
      v-if="!to"
      href="#"
      class="dropdown-item"
      :class="{ active: active, disabled: disabled }"
      v-bind="$attrs"
      @click.prevent.stop="click"
      :to="to"
    >
      <slot />
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      to: {
        type: [ Object, String ],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },

    emits: [ 'click' ],

    methods: {
      click(event) {
        this.$emit('click', event)
      }
    }
  }
</script>
