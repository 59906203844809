import axios from 'axios'
import handleErrors from '@/shared/api/utils/handleErrors'
import authService from '@/services/auth.service'
import { settings } from '@/settings'

const instance = axios.create({
  baseURL: settings.api.url
})

instance.interceptors.request.use(function (config) {
  const impersonated = authService.getImpersonated()

  if (impersonated && !config.authenticatedNotImpersonated) {
    config.headers.UserScope = impersonated.id
  }

  config.headers.Authorization = 'Bearer ' + authService.getToken()

  return config
})

instance.interceptors.response.use(handleSuccess, handleErrors)

function handleSuccess(config) {
  return config
}

export default instance

