<template>
  <div class="AppLoading" :class="{ 'AppLoading--spaced': spaced, 'AppLoading--inline': inline }">
    <div class="AppLoading__ripple"><div /><div /></div>
  </div>
</template>

<script>
  export default {
    props: {
      inline: {
        type: Boolean,
        default: false
      },
      spaced: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.vars.all'

  .AppLoading
    display: flex
    justify-content: center
    align-items: center

    &.AppLoading--spaced
      height: 300px

    &.AppLoading--inline
      display: inline-block
      height: auto

    .AppLoading__ripple
      display: inline-block
      position: relative
      width: 80px
      height: 80px

    .AppLoading__ripple div
      position: absolute
      border: 4px solid $primary
      opacity: 1
      border-radius: 50%
      animation: AppLoading__ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite

    .AppLoading__ripple div:nth-child(2)
      animation-delay: -0.5s

  @keyframes AppLoading__ripple
    0%
      top: 36px
      left: 36px
      width: 0
      height: 0
      opacity: 1

    100%
      top: 0px
      left: 0px
      width: 72px
      height: 72px
      opacity: 0
</style>
