<template>
  <div class="AppChart" :class="{ 'under-construction': underConstuction }">
    <canvas ref="chart" />
  </div>
</template>

<script>
  import { Chart, registerables } from 'chart.js'
  import 'chartjs-adapter-date-fns'

  import { settings } from '@/settings'

  Chart.register(...registerables)

  export default {
    props: {
      type: {
        type: String,
        default: 'line'
      },

      data: {
        type: Object,
        default: null
      },

      options: {
        type: Object,
        default: null
      },

      plugins: {
        type: Array,
        default: null
      }
    },

    setup() {
      return {
        chart: null
      }
    },

    data() {
      return {
        underConstuction: settings.underConstuction
      }
    },

    watch: {
      data: {
        immediate: true,
        handler(data) {
          if (this.chart && this.chart.update) {
            this.chart.data = data
            this.chart.update()
          }
        }
      },

      options: {
        immediate: true,
        handler(options) {
          if (this.chart && this.chart.update) {
            this.chart.options = options
            this.chart.update()
          }
        }
      },

      plugins: {
        immediate: true,
        handler(plugins) {
          if (this.chart && this.chart.update) {
            this.chart.plugins = plugins
            this.chart.update()
          }
        }
      }
    },

    async mounted() {
      const elmt = this.$refs.chart
      const ctx = elmt.getContext('2d')

      this.chart = new Chart(ctx, {
        type: this.type,
        data: this.data,
        options: this.options
      })
    },

    beforeUnmount() {
      if (this.chart && this.chart.destroy) {
        this.chart.destroy()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all'

  .AppChart
    height: 100%

    &.under-construction
      pointer-events: none
      position: relative

      &::before
        content: 'Under construction'
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        justify-content: center
        align-items: center
        color: black
        background: rgba(white, .8)
        font-size: 12px
        color: $primary
        text-transform: uppercase
</style>
