<template>
  <main class="LayoutInvoice layout-invoice">
    <router-view />
  </main>
</template>

<style lang="sass">
  @import '@/sass/styles.vars.all'

  body
    overflow: auto
</style>
