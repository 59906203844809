import axios from 'axios'
import handleErrors from '@/shared/api/utils/handleErrors'
import { settings } from '@/settings'

const instance = axios.create({
  baseURL: settings.api.url
})

instance.interceptors.response.use(handleSuccess, handleErrors)

function handleSuccess(config) {
  return config
}

export default instance

