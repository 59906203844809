<template>
  <!-- FUTURES -->

  <div v-if="allocation.type === 'futures'" class="AllocationCard" :class="computePositionClass(allocation.position_status.position)">
    <div class="card" @click.stop="go(allocation.id)">
      <div class="card-body">
        <AllocationPosition :position="allocation.position_status.position" :time="allocation.position_status.when" :state="allocation.state" />

        <div class="AllocationCard__icon">
          <IconBot />
          <ExchangeLogo class="AllocationCard__exchange" :exchange="allocation.exchange" v-if="allocation.exchange" />
        </div>

        <h2 class="AllocationCard__name">
          {{ allocation.name }}
        </h2>

        <div class="AllocationCard__balance">
          <label>Current wallet</label>
          <strong>${{ formatDecimal(allocation.balance) }}</strong>
        </div>

        <!--
        <div v-if="!isWaiting(allocation)">
          <div class="AllocationCard__position">
            <label>Last update</label>
            <strong v-if="allocation.wallet_composition.when">
              {{ formatRelativeDate(allocation.wallet_composition.when) }}
            </strong>
            <strong v-else>
              …
            </strong>
          </div> -->

        <!-- <div class="AllocationCard__prices">
            <div v-if="allocation.position_status.last_position">
              <div class="AllocationCard__price">
                <label>Entry price</label>
                <strong>${{ formatDecimal(allocation.position_status.last_position.entry) }}</strong>
              </div>

              <div class="AllocationCard__price">
                <label>Current price</label>
                <strong>${{ formatDecimal(allocation.position_status.last_position.current) }}</strong>
              </div>
            </div>
          </div>
        </div>
        -->

        <!-- v-if="isWaiting(allocation)" -->
        <div>
          <div class="AllocationCard__position">
            <label>Last position</label>
            <strong v-if="allocation.position_status.when">
              {{ formatRelativeDate(allocation.position_status.when) }}
            </strong>
            <strong v-else>
              …
            </strong>
          </div>
        </div>

        <div class="AllocationCard__waiting" v-if="allocation.chart.length < 2">
          <i class="bi bi-hourglass" />
        </div>

        <div :style="{ visibility: isWaiting(allocation) ? 'hidden' : 'visible' }">
          <!--
          <div class="AllocationCard__chart" v-if="allocation.chart.length >= 2">
            <AppSparkline :points="allocation.chart" />
          </div>
          -->

          <div class="AllocationCard__perf">
            <div v-if="allocation.perf">
              <div class="AllocationCard__perf__pnl">
                <AppValue :value="allocation.perf.pnl || 0" after="%" />
              </div>
              <div class="AllocationCard__perf__roe">
                ${{ formatDecimal(allocation.perf.roe || 0) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SPOT -->

  <div v-if="allocation.type === 'spot'" class="AllocationCard">
    <div class="card" @click.stop="go(allocation.id)">
      <div class="card-body">
        <AllocationPosition position="spot" :state="allocation.state" />

        <div class="AllocationCard__icon">
          <IconBot />
          <ExchangeLogo class="AllocationCard__exchange" :exchange="allocation.exchange" v-if="allocation.exchange" />
        </div>

        <h2 class="AllocationCard__name">
          {{ allocation.name }}
        </h2>

        <div class="AllocationCard__balance">
          <label>Current wallet</label>
          <strong>${{ formatDecimal(allocation.balance) }}</strong>
        </div>

        <div class="AllocationCard__position">
          <label>Last update</label>
          <strong v-if="allocation.wallet_composition.when">
            {{ formatRelativeDate(allocation.wallet_composition.when) }}
          </strong>
          <strong v-else>
            …
          </strong>
        </div>

        <div class="AllocationCard__waiting" v-if="allocation.chart.length < 2">
          <i class="bi bi-hourglass" />
        </div>

        <div :style="{ visibility: isWaiting(allocation) ? 'hidden' : 'visible' }">
          <!--
          <div class="AllocationCard__chart" v-if="allocation.chart.length >= 2">
            <AppSparkline :points="allocation.chart" />
          </div>
          -->

          <div class="AllocationCard__perf">
            <div v-if="allocation.perf">
              <div class="AllocationCard__perf__pnl">
                <AppValue :value="allocation.perf.pnl || 0" after="%" />
              </div>
              <div class="AllocationCard__perf__roe">
                ${{ formatDecimal(allocation.perf.roe || 0) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import AppSparkline from '@/components/AppSparkline'
  import AppValue from '@/components/AppValue'
  import AllocationPosition from '@/components/AllocationPosition'
  import ExchangeLogo from '@/components/ExchangeLogo'
  import IconBot from '@/components/IconBot'

  import { formatDecimal } from '@/utils/number'
  import { formatDate, formatRelativeDate } from '@/utils/date'

  export default {
    components: {
      // AppSparkline,
      AppValue,
      AllocationPosition,
      ExchangeLogo,
      IconBot
    },

    props: {
      allocation: {
        type: Object,
        default: () => ({})
      }
    },

    methods: {
      formatDecimal,
      formatDate,
      formatRelativeDate,

      go(id) {
        this.$router.push({ name: 'allocationDetails', params: { id } })
      },

      computePositionClass(position) {
        return { ['AllocationCard--' + position]: true }
      },

      isWaiting(allocation) {
        return allocation && allocation.position_status && allocation.position_status.position === 'waiting'
      }
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.vars.all.sass'

  .AllocationCard
    height: 100%

    .card
      height: 100%
      width: 100%
      cursor: pointer

    .card-body
      text-align: center
      padding: $spacer-4 $spacer

  .AllocationCard__icon
    display: block
    width: 75px
    height: auto
    border-radius: 999px
    padding: $spacer
    margin: $spacer auto
    border: 2px solid $primary
    position: relative

    svg
      width: 100%
      height: auto
      display: block

      > path
        fill: $primary

  .AllocationCard__exchange
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, 0%)
    width: 2em !important
    height: 2em !important
    overflow: hidden
    background: white
    border-radius: 999px
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid $primary

  .AllocationCard__name
    height: 2.5em
    font-size: 1.2rem
    text-align: center
    padding: 0 $spacer
    color: $primary
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

  .AllocationCard__prices
    > div
      div
        display: inline-block
        margin: 0

        &:first-child
          border-right: 1px solid $gray-300
          text-align: right
          padding-right: 1em

        &:last-child
          text-align: left
          padding-left: 1em

  .AllocationCard__balance,
  .AllocationCard__price,
  .AllocationCard__position
      label
        display: block
        text-transform: uppercase
        font-size: .65rem
        white-space: nowrap
        color: $gray-600

      strong::first-letter
        text-transform: uppercase

  .AllocationCard__balance
    margin: $spacer auto

  .AllocationCard__perf
    text-align: center
    margin: $spacer 0 0

  .AllocationCard__perf__pnl
    font-size: 1.75em

  .AllocationCard__waiting,
  .AllocationCard__chart
    height: 50px
    margin: auto
    margin: $spacer 0
    font-size: 2.5em

    .AllocationCard--waiting &
      opacity: .3
</style>
