<template>
  <div class="AllocationPosition" :class="computePositionClass(position, state)">
    {{ state === 'on' ? position : 'Disconnected' }}
    <!--
      <time v-if="position !== 'waiting' && time">
      {{ duration }}
      </time>
    -->
  </div>
</template>

<script>
  import { formatDuration } from '@/utils/date'

  export default {
    props: {
      position: {
        type: String,
        default: ''
      },

      state: {
        type: String,
        default: 'on'
      },

      time: {
        type: [ Number, String ],
        default: ''
      }
    },

    data() {
      return {
        interval: null,
        duration: ''
      }
    },

    mounted() {
      this.interval = setInterval(() => {
        this.duration = formatDuration(this.time)
      }, 1000)
    },

    unmounted() {
      clearInterval(this.interval)
    },

    methods: {
      formatDuration,

      computePositionClass(position, state) {
        if (state === 'on') {
          return { ['AllocationPosition--' + position.toLowerCase().replace(/ /g, '-')]: true }
        } else {
          return { ['AllocationPosition--off']: true }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AllocationPosition
    border-radius: .5em
    padding: .25em 1em
    display: inline-block
    text-transform: uppercase

    time
      font-weight: normal
      text-transform: none
      font-size: .9rem

  .AllocationPosition--spot
    background: tint-color($tertiary, 86)
    color: $tertiary

  .AllocationPosition--long
    background: tint-color($success, 90)
    color: $success

  .AllocationPosition--short
    background: tint-color($danger, 90)
    color: $danger

  .AllocationPosition--waiting
    background: tint-color($info, 90)
    color: $info

  .AllocationPosition--off
    background: tint-color($gray-500, 90)
    color: $gray-500

  .AllocationPosition--in-position
    background: tint-color($info, 90)
    color: $info
</style>
